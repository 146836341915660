import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import renderDaterange from "../../../../Components/Forms/daterange";
import { renderselectonlyname } from "../../../../Components/Forms/renders";

const HeaderFinancieel = ({ changedata, boekingen }) => {
  const onchangedatum = (vals) => {
    setTimeout(() => {
      changedata({ datum: vals });
    }, 200);
  };

  const onchangeitem = (vals) => {
    console.log(vals)
    setTimeout(() => {
      changedata({ items: vals });
    }, 200);
  };
  const processItems = (boekingen, path, soort) => {
    const seen = new Set();
    return boekingen
      .map((item) => path.split(".").reduce((acc, key) => acc?.[key], item)) // Volg het pad naar het gewenste object
      .filter(Boolean)
      .map(({ naam, _id }) => ({ naam, _id, soort }))
      .filter((item) => {
        if (!seen.has(item._id)) {
          seen.add(item._id);
          return true;
        }
        return false;
      });
  };

  // Verwerk arrangementen en ervaringen samen
  const combinedItems = [...processItems(boekingen, "arrangementdatum.ervaring", "arrangement"), ...processItems(boekingen, "ervaringdatum.ervaring", "ervaring")];

  // Sorteer de gecombineerde items op naam (optioneel)
  combinedItems.sort((a, b) => a.naam.localeCompare(b.naam));

  return (
    <form>
      <div className="row container filter ">
        <div className="col-md-2 col-sm-6 col-xs-12">
          <Field name="datum" type="number" mindate={true} maxdate={false} component={renderDaterange} label="Kies een datum" onChange={onchangedatum} />
        </div>
        <div className="col-md-2 col-sm-6 col-xs-12">
          <Field name="soort" component={renderselectonlyname} label="Soort" options={combinedItems} inputnaam="naam" onChange={onchangeitem} />
        </div>
      </div>
      <hr />
      <div className="row container filter my-2 "></div>
    </form>
  );
};

const reduxConnectedForm = reduxForm({ form: "financieelheader" })(HeaderFinancieel);

const mapStateToProps = (state) => ({
  initialValues: {
    datum: { startdate: moment().subtract(7, "days"), enddate: new Date() },
  },
});

export default connect(mapStateToProps)(reduxConnectedForm);
