import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import HeaderFinancieel from "./Components/header";
import { getervaringen } from "../../Ervaringen/Reducers/actions";
import { saveboekingen, fetchperdatun } from "../Reducers/actions";
import { message, fixmessage } from "../../../Reducers/loading/actions";
const FinancieelBoekingen = ({ ervaring, fetchperdatun, boekingen }) => {
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState("Nieuw");
  const [datum, setDatum] = useState({ startdate: moment().subtract(14, "days"), enddate: new Date() });
  const [activiteiten, setActiviteiten] = useState([]);

  useEffect(() => {
    fetchperdatun({ datum }).then((sdata) => {
      if (sdata.results) {
        setLoaded(true);
      }
    });
  }, [status]);

  const changedata = (vals) => {
    if (vals.datum) {
      setDatum(vals.datum);
      fetchperdatun({ datum: vals.datum, items: activiteiten }).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    } else if (vals.items) {
      setActiviteiten(vals.items);
      console.log(datum);
      fetchperdatun({ datum: datum, items: vals.items }).then((sdata) => {
        if (sdata.results) {
          setLoaded(true);
        }
      });
    }
  };

  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <div className="box-title">
          <HeaderFinancieel changedata={changedata} boekingen={boekingen} />
        </div>
      </div>

      {loaded && (
        <div className="box-body box-profile">
          <div className="box-header with-border d-flex">
            <div className="m-2"></div>

            <div className="box-body"></div>
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return { username: state.auth.user, ervaring: state.ervaringen.item, boekingen: state.boekingen.items };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getervaringen,
  saveboekingen,
  fetchperdatun,
})(FinancieelBoekingen);
